.shareLinkInputContainer {
    min-height: 8px;
}

.shareLinkInput, .shareLinkInput:focus {
    width: 100%;
    display: none;
    outline: 0;
    background: transparent;
    border: 0 dashed gray;
    border-bottom-width: 1px;
}

.copyResult {
    font-size: 12px;
    color: dimgray;
    font-style: italic;
    margin-bottom: 16px;
}

.buttonSet {
    display: flex;
    flex-direction: column;
    margin: -6px 0;
}

.buttonContainer {
    flex: 1;
    margin: 6px 0;
}

.shareButton,
.faqButton {
    width: 100%;
}

.lastUpdate {
    font-size: 10px;
    color: gray;
}

@media (max-width: 680px) {
    .aside {
        margin-top: 16px;
    }

    .shareLinkInputContainer {
        min-height: unset;
    }

    .buttonSet {
        flex-direction: row;
        margin: 0 -6px;
    }

    .buttonContainer {
        flex: 1;
        margin: 0 6px;
    }
}