.presentation {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.backdrop,
.content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.backdrop {
    background: rgba(255, 255, 255, 0.75);
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
}
