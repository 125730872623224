.color0 {
    background: #FEC;
}
div.color0 {
    border: 2px solid #544;color: #544;
}
tr.color0 {
    color: #544;
}

.color1 {
    background: #FC9;
}
div.color1 {
    border: 2px solid #543;color: #543;
}
tr.color1 {
    color: #543;
}

.color2 {
    background: #EED;
}
div.color2 {
    border: 2px solid #432;color: #432;
}
tr.color2 {
    color: #432;
}

.color3 {
    background: #FFE;
}
div.color3 {
    border: 2px solid #554;color: #554;
}
tr.color3 {
    color: #554;
}

.color4 {
    background: #F73;
}
div.color4 {
    border: 2px solid #521;color: #521;
}
tr.color4 {
    color: #521;
}

.color5 {
    background: #E86;
}
div.color5 {
    border: 2px solid #422;color: #422;
}
tr.color5 {
    color: #422;
}

.color6 {
    background: #EBB;
}
div.color6 {
    border: 2px solid #433;color: #433;
}
tr.color6 {
    color: #433;
}

.color7 {
    background: #8EF;
}
div.color7 {
    border: 2px solid #245;color: #245;
}
tr.color7 {
    color: #245;
}

.color8 {
    background: #9FB;
}
div.color8 {
    border: 2px solid #353;color: #353;
}
tr.color8 {
    color: #353;
}

.color9 {
    background: #FCF;
}
div.color9 {
    border: 2px solid #535;color: #535;
}
tr.color9 {
    color: #535;
}
