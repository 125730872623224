.dialog {
    background: #ffffff;
    color: #333333;
    border: 1px solid #c5c5c5;
    border-radius: 3px;
    max-width: 800px;
    max-height: 100%;

    display: flex;
    flex-direction: column;
}

.title {
    padding: 0.4em 1em;
    position: relative;
    border-radius: 3px;
    border: 1px solid #dddddd;
    background: #e9e9e9;
    color: #333333;
    font-weight: 700;
}

.content {
    flex: 1;
    overflow: auto;
}

.actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-top: 1px solid #dddddd;
    padding: 1em;
}
