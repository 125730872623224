.separator td, .separator th {
    height: 0px !important;
    padding: 0px !important;
    margin: 0px !important;
    border: 0px solid transparent;
}

.separator td {
    border-top: 1px solid #ddd;
}
