.searchBar {
    width: 100%;
    height: 32px;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    border: 1px solid #996600;
}


    /* padding: 10px 0px; */

.termInfo {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 100%;
    margin: 0;
    padding: 0 8px;
    line-height: 24px;
    border-right: 1px #960 solid;
    background: #c80;
    color: #fff;
    text-align: center;
}

@media (max-width: 680px) {
    .termInfo {
        display: none;
    }

}

.inputContainer {
    flex: 1;
}

.input {
    outline: 0;
    border-radius: 0;
    border: 0;
    font: inherit;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 8px;
    line-height: 24px;
    background: #eee;
}

.input:focus {
    outline: 0px;
}
