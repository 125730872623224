.button {
    font-family: inherit;
    font-size: 13px;
    font-weight: 600;

    border-radius: 4px;

    background: #e6e6e6;
    border: 1px solid #d3d3d3;
    color: #555555;
    text-decoration: none;

    display: inline-block;
    position: relative;
    padding: 0;
    line-height: normal;
    margin-right: .1em;
    cursor: pointer;
    vertical-align: middle;
    text-align: center;
    overflow: visible;
}

.button:hover {
    border: 1px solid #db9370;
    background: #eee9e9;
    color: #292421;
}

.text {
    padding: .4em 1em;
    display: block;
    line-height: normal;
}
