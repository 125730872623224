.header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #123456;
    border-bottom: 1px solid #000;
    z-index: 1;
}

.headerInner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 16px;
}

.logo {
    display: block;
    width: 128px;
    height: 48px;
    background: transparent url('../images/logo.png') no-repeat center center;
    background-size: 96px 24px;
    padding: 12px 16px;
    margin: 0 -16px;

}

.siteName {
    visibility: hidden;
}

.searchBarContainer {
    margin-left: 16px;
    flex: 1;
}

@media (max-width: 680px) {
    .logo {
        width: 100%;
    }

    .searchBarContainer {
        flex: unset;
        width: 100%;
        margin-left: 0;
        padding-bottom: 12px;
    }
}
